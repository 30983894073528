<template>
    <div>
        <apexchart type="bar" height="350" :options="computedSettings" :series="computedXAxisData"></apexchart>
    </div>
</template>
<script>
    export default {
        name: 'ColumnChart',
        props: {
            data: {
                type: Array,
                required: true,
                default: () => []
            },
            settings: {
                type: Object,
                default: () => {}
            },
            height: {
                type: String,
                default: '175px'
            },
        },
        data() {
            return {
                chartOptions: {
                    
                },
            } 
        },
        computed: {
            computedSettings() {
                return {
                    chart: {
                        id: 'column-chart',
                        height: 350,
                        type: 'bar',
                        zoom: {
                            type: 'x',
                            enabled: true,
                            autoScaleYaxis: true
                        },
                        toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                        },
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            columnWidth: '50%',
                        }
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        width: 2
                    },
                    legend: {
                        show: true
                    },
                    grid: {
                        row: {
                            colors: ['#fff', '#f2f2f2']
                        }
                    },
                    xaxis: {
                        title: {
                            text: "Period",
                            style: {
                                // colors: "#FF1654"
                                fontWeight: 500,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                            }
                        },
                        labels: {
                            rotate: -45
                        },
                        categories: this.computedYAxis,
                        tickPlacement: 'on',
                    },
                    yaxis: {
                        title: {
                            text: this.data[0].name_of_data,
                            style: {
                                // colors: "#FF1654"
                                fontWeight: 500,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                            }
                        },
                    },
                    fill: {
                        // type: 'gradient',
                        gradient: {
                            shade: 'light',
                            type: "horizontal",
                            shadeIntensity: 0.25,
                            gradientToColors: undefined,
                            inverseColors: true,
                            opacityFrom: 0.85,
                            opacityTo: 0.85,
                            stops: [50, 0, 100]
                        },
                    }
                }
            },
            computedYAxis() {
                return this.data.map(item => {
                    return item.period
                })
            },
            computedXAxisData() {
                if(this.settings.multi == undefined){
                    const data = [{
                            name: 'Stats',
                            data: this.data.map(item => {
                                return item.total
                            })
                    }]
                    return data
                }
                else{
                    const data = []
                    for(var i = 0 ; i < this.settings.noOfColumns ; i++){
                        data.push(
                            {
                                name:this.settings.names[i],
                                data: this.data.map(item => {
                                    return item[this.settings.keys[i]]
                                })
                            }
                        )
                    }
                    return data
                }
            }
        }
    }
</script>
<style lang="scss" module>
</style>
