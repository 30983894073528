import userRoutes from "./user";
import publicRoutes from "./public";

export default [
    {
        path: "/",
        name: "home",
        component: () => import("../../pages/master/Master"),
        meta: {
            title: "Home",
            auth: true,
        },
        children: [
            ...userRoutes,
            ...publicRoutes,
        ]
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: "NetEdge Intranet Login"
        },
        component: () => import("Pages/public/login/Login.vue")
    },
    {
        path: "/help/:id",
        name: "help",
        meta: {
            title: "Help",
            auth: true,
        },
        component: () => import("../../pages/user/help"),
    },
    {
        path: "/unauthorize",
        name: "unauthorize",
        meta: {
            title: "Unauthorized"
        },
        component: () => import("../../pages/errorPages/Unauthorized")
    },
    {
        path: "*",
        name: "notFound",
        meta: {
            title: "Not Found"
        },
        component: () => import("../../pages/errorPages/NotFound")
    },

];
