export default {
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        showLoader() {
            this.isLoading = true;
            // this.$store.commit('setIsLoading',true);
        },
        hideLoader() {
            this.isLoading = false;
            // this.$store.commit('setIsLoading',false);
        },
    }
}


