export default [
    //sagar routes
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            auth: true,
        },
        component: () => import("../../pages/user/dashboard"),
    },
    {
        path: "/staffLeaveList",
        name: "staffLeaveList",
        meta: {
            title: "Staff Leave List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/applyLeave/list"),
    },
    {
        path: "/applyForLeave",
        name: "applyForLeave",
        meta: {
            title: "Apply For Leave",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/applyLeave/add"),
    },
    {
        path: "/applyForLeave/edit/:id",
        name: "editLeave",
        meta: {
            title: "Edit Leave",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/applyLeave/add"),
    },
    {
        path: "/leaveBalance",
        name: "leaveBalance",
        meta: {
            title: "Leave Balance",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/leaveBalance/list"),
    },
    {
        path: "/applyOD",
        name: "applyOD",
        meta: {
            title: "Apply OD Request",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/odRequest/add"),
    },
    {
        path: "/OD/edit/:id",
        name: "ODEdit",
        meta: {
            title: "Edit OD Request",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/odRequest/add"),
    },
    {
        path: "/onDutyRequestList",
        name: "onDutyRequestList",
        meta: {
            title: "On Duty Request List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/odRequest/list"),
    },
    {
        path: "/applyForShortLeave",
        name: "applyForShortLeave",
        meta: {
            title: "Apply For Short Leave",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/shortLeave/add"),
    },
    {
        path: "/shortLeave/edit/:id",
        name: "shortLeaveEdit",
        meta: {
            title: "Edit Short Leave",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/shortLeave/add"),
    },
    {
        path: "/shortLeaveList",
        name: "shortLeaveList",
        meta: {
            title: "Short Leave List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/shortLeave/list"),
    },
    {
        path: "/holidayList",
        name: "holidayList",
        meta: {
            title: "Holiday List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/holiday/list"),
    },
    {
        path: "/addHoliday",
        name: "addHoliday",
        meta: {
            title: "Add Holiday",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/holiday/add"),
    },
    {
        path: "/addHoliday/:id",
        name: "editHoliday",
        meta: {
            title: "Edit Holiday",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/holiday/add"),
    },
    {
        path: "/companyCalendar",
        name: "companyCalendar",
        meta: {
            title: "Company Calendar",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/calendar/CompanyCalendar"),
    },
    {
        path: "/leaveStatement",
        name: "leaveStatement",
        meta: {
            title: "Leave Statement",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/leaveStatement/list"),
    },
    {
        path: "/leaveOpeningBalance",
        name: "leaveOpeningBalance",
        meta: {
            title: "Leave Opening Balance",
            auth: true,
        },
        component: () =>
        import("../../pages/user/hr/leave/leaveOpeningBalance/list"),
    },
    {
        path: "/leaveRegister",
        name: "leaveRegister",
        meta: {
            title: "Leave Register",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/leaveRegister/list"),
    },
    {
        path: "/shortLeaveException",
        name: "shortLeaveException",
        meta: {
            title: "Leave Register",
            auth: true,
        },
        component: () => import("../../pages/user/hr/exception/shortLeave/list"),
    },
    {
        path: "/addShortLeaveException",
        name: "addShortLeaveException",
        meta: {
            title: "Add Short Leave Exception",
            auth: true,
        },
        component: () => import("../../pages/user/hr/exception/shortLeave/add"),
    },
    {
        path: "/editshortLeaveException/:id",
        name: "edithortLeaveException",
        meta: {
            title: "Edit Short Leave Exception",
            auth: true,
        },
        component: () => import("../../pages/user/hr/exception/shortLeave/add"),
    },
    {
        path: "/userList",
        name: "userList",
        meta: {
            title: "User List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/team/profile/list"),
    },
    {
        path: "/onDutyException",
        name: "onDutyException",
        meta: {
            title: "Leave Register",
            auth: true,
        },
        component: () => import("../../pages/user/hr/exception/onDuty/list"),
    },
    {
        path: "/addOnDutyException",
        name: "addOnDutyException",
        meta: {
            title: "Add On Duty Exception",
            auth: true,
        },
        component: () => import("../../pages/user/hr/exception/onDuty/add"),
    },
    {
        path: "/editOnDutyException/:id",
        name: "editOnDutyException",
        meta: {
            title: "Edit On Duty Exception",
            auth: true,
        },
        component: () => import("../../pages/user/hr/exception/onDuty/add"),
    },
    // settings
    {
        path: "/tipOfTheDay",
        name: "tipOfTheDay",
        meta: {
            title: "Tip Of The Day",
            auth: true,
        },
        component: () =>
        import("../../pages/user/settings/master/tipOfTheDay/list"),
    },
    {
        path: "/addTipOfTheDay",
        name: "addTipOfTheDay",
        meta: {
            title: "Add Tip Of The Day",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/tipOfTheDay/add"),
    },
    {
        path: "/tipOfTheDay/edit/:id",
        name: "editTipOfTheDay",
        meta: {
            title: "Edit Tip Of The Day",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/tipOfTheDay/add"),
    },
    // admin module mapping
    {
        path: "/roleUserMapping",
        name: "roleUserMapping",
        meta: {
            title: "Role User Mapping",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/roleUserMapping/mapping"),
    },
        {
        path: "/roleMenuMapping",
        name: "roleMenuMapping",
        meta: {
            title: "Role Menu Mapping",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/roleMenuMapping/mapping"),
    },
    {
        path: "/menuRoleMapping",
        name: "menuRoleMapping",
        meta: {
            title: "Menu Role Mapping",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/menuRoleMapping/mapping"),
    },
    // attendance module
    // sagar 12-06-2023
    {
        path: "/attendanceTimeInOut",
        name: "attendanceTimeInOut",
        meta: {
            title: "Attendance Time In Out",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/attendanceTimeInOut/punchIn"),
    },
    // sagar 14-06-2023
    { 
        path: "/changePassword",
        name: "changePassword",
        meta: {
            title: "Change Password",
            auth: true,
        },
        component: () => import("../../pages/user/changePassword"),
    },
    // sagar 16-06-2023
    {
        path: "/staffAwardList",
        name: "staffAwardList",
        meta: {
            title: "Staff Award List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/award/list"),
    },
    {
        path: "/staffAward",
        name: "addStaffAward",
        meta: {
            title: "Add Staff Award",
            auth: true,
        },
        component: () => import("../../pages/user/hr/award/add"),
    },
    //account Module routes start
    // sagar comment account modules routes in production 03-10-2023 
    {
        path: "/expenseClaimList",
        name: "expenseClaimList",
        meta: {
            title: "Expense Claim List",
            auth: true,
        },
        component: () => import("../../pages/user/account/reimbursement/list"),
    },
    {
        path: "/expenseClaim/add",
        name: "addExpenseClaim",
        meta: {
            title: "Add Expense Claim",
            auth: true,
        },
        component: () => import("../../pages/user/account/reimbursement/add"),
    },
    {
        path: "/expenseClaim/edit/:id",
        name: "editExpenseClaim",
        meta: {
            title: "Edit Expense Claim",
            auth: true,
        },
        component: () => import("../../pages/user/account/reimbursement/add"),
    },
    {
        path: "/expenseClaim/view/:id",
        name: "viewExpenseClaim",
        meta: {
            title: "View Expense Claim",
            auth: true,
        },
        component: () => import("../../pages/user/account/reimbursement/add"),
    },
    {
        path: "/accountReimbursement",
        name: "accountReimbursement",
        meta: {
            title: "Account Reimbursement List",
            auth: true,
        },
        component: () => import("../../pages/user/account/payment/list"),
    },
    {
        path: "/viewExpensePayment/:id",
        name: "viewExpensePayment",
        meta: {
            title: "View Account Reimbursement",
            auth: true,
        },
        component: () => import("../../pages/user/account/payment/add"),
    },
          // sagar 25-07-2023 add 3 routes
          {
            path: "/UpdateStaffAppraisal",
            name: "UpdateStaffAppraisal",
            meta: {
                title: "Update Staff Appraisal",
                auth: true,
            },
            component: () => import("../../pages/user/hr/appraisal/appraisal"),
        },
        {
            path: "/ViewAppraisal",
            name: "ViewAppraisal",
            meta: {
                title: "View Appraisal",
                auth: true,
            },
            component: () => import("../../pages/user/hr/appraisal/list"),
        },
        {
            path: "/ViewSalaryFixation",
            name: "ViewSalaryFixation",
            meta: {
                title: "View Salary Fixation",
                auth: true,
            },
            component: () => import("../../pages/user/hr/appraisal/view"),
        },
        {
            path: "/SalaryHistory",
            name: "SalaryHistory",
            meta: {
                title: "Salary History",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/salaryHistory/list"),
        },
        {
            path: "/DeductionList",
            name: "DeductionList",
            meta: {
                title: "Deduction List",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/deduction/list"),
        },
        {
            path: "/addDeduction",
            name: "add Deduction",
            meta: {
                title: "add Deduction",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/deduction/add"),
        },
        {
            path: "/deduction/edit/:id",
            name: "editDeduction",
            meta: {
                title: "Edit Deduction",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/deduction/add"),
        },
        {
            path: "/SalaryProcessing",
            name: "SalaryProcessing",
            meta: {
                title: "Salary Processing",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/salaryProcessing/list"),
        },
        {
            path: "/MySalarySlip",
            name: "MySalarySlip",
            meta: {
                title: "My Salary Slip",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/salarySlip/list"),
        },
        {
            path: "/AdditionalList",
            name: "AdditionalList",
            meta: {
                title: "Additional List",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/additionalPayment/list"),
        },
        {
            path: "/addAdditionalPayment",
            name: "additionalPayment",
            meta: {
                title: "Add Additional Payment",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/additionalPayment/add"),
        },
        {
            path: "/additionalPayment/edit/:id",
            name: "editAdditional",
            meta: {
                title: "Edit Additional Payment",
                auth: true,
            },
            component: () => import("../../pages/user/account/payroll/additionalPayment/add"),
        },
        // account Module routes end
        {
            path: "/announcementList",
            name: "announcementList",
            meta: {
                title: "List Announcement",
                auth: true,
            },
            component: () => import("../../pages/user/settings/master/announcement/list"),       
         },
         {
            path: "/announcement",
            name: "addAnnouncement",
            meta: {
                title: "Add Announcement",
                auth: true,
            },
            component: () => import("../../pages/user/settings/master/announcement/add"),       
         },
         {
            path: "/announcement/:id",
            name: "editAnnouncement",
            meta: {
                title: "Edit Announcement",
                auth: true,
            },
            component: () => import("../../pages/user/settings/master/announcement/add"),       
         },
        {
            path: "/announcement/view/:id",
            name: "announcementView",
            meta: {
                title: "View Announcement",
                auth: true,
            },
            component: () => import("../../pages/user/settings/master/announcement/view"),       
         },
      //created by sagar on 21-09-2023
      {
        path: "/newjoinee",
        name: "newJoinee",
        meta: {
            title: "New Joinee",
            auth: true,
        },
        component: () => import("../../pages/user/settings/userManagement/joinee"),       
     },
         //created by sagar on 22-09-2023
         {
            path: "/vTigerReport",
            name: "vTigerReport",
            meta: {
                title: "vTiger Report",
                auth: true,
            },
            component: () => import("../../pages/user/settings/vtiger/list"),       
         },
         {
            path: "/userVtigerData",
            name: "userVtigerData",
            meta: {
                title: "Users Vtiger Data",
                auth: true,
            },
            component: () => import("../../pages/user/settings/vtiger/list"),       
         },
        //created by sagar 26-09-2023
        {
            path: "/separationList",
            name: "separationList",
            meta: {
                title: "separation  List",
                auth: true,
            },
            component: () => import("../../pages/user/hr/separation/list"),       
         },
         {
            path: "/addSeparation",
            name: "addSeparation",
            meta: {
                title: "add Separation ",
                auth: true,
            },
            component: () => import("../../pages/user/hr/separation/add"),       
         },
         {
            path: "/editSeparation/:id",
            name: "editSeparation",
            meta: {
                title: "edit Separation ",
                auth: true,
            },
            component: () => import("../../pages/user/hr/separation/add"),       
         },
            //  created by sagar on 10-10-2023
        {
            path: "/netPayDays",
            name: "netPayDays",
            meta: {
                title: "Net Pay Days Report",
                auth: true,
            },
            component: () => import("../../pages/user/hr/netPayDays/list"),
        },
        {
            path: "/LoginReport",
            name: "LoginReport",
            meta: {
                title: "Login Report",
                auth: true,
            },
            component: () => import("../../pages/user/hr/loginReport/list"),
        },
        {
            path: "/extensionUpdate",
            name: "extensionUpdate",
            meta: {
                title: "Extension Update",
                auth: true,
            },
            component: () => import("../../pages/user/admin/masterData/extensionUpdate/list"),
        },
    //sagar end
     // created by sagar on 16-10-2023
        // LFC learning for career
        {
            path: "/trainerRegistrationList",
            name: "trainerRegistrationList",
            meta: {
                title: "Trainer Registration List",
                auth: true,
            },
            component: () => import("../../pages/user/LFC/trainerRegistration/list"),
        },
        {
            path: "/trainerRegistrationForm",
            name: "trainerRegistrationListForm",
            meta: {
                title: "Trainer Registration Form Add",
                auth: true,
            },
            component: () => import("../../pages/user/LFC/trainerRegistration/add"),
        },
        {
            path: "/trainerRegistrationForm/edit/:id",
            name: "trainerRegistrationListFormEdit",
            meta: {
                title: "Trainer Registration Form Edit",
                auth: true,
            },
            component: () => import("../../pages/user/LFC/trainerRegistration/add"),
        },
        {
            path: "/trainerRegistrationForm/view/:id",
            name: "trainerRegistrationListFormView",
            meta: {
                title: "Trainer Registration Form View",
                auth: true,
            },
            component: () => import("../../pages/user/LFC/trainerRegistration/add"),
        },
    //sagar end
    //created by sagar on 26-10-2023 job positions list and add
    {
        path: "/openPositionsList",
        name: "openPositionsList",
        meta: {
            title: "Job Open Positions List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/career/openPositions/list"),
    },
    {
        path: "/position",
        name: "position",
        meta: {
            title: "Add Position",
            auth: true,
        },
        component: () => import("../../pages/user/hr/career/openPositions/add"),
    },
    {
        path: "/position/edit/:id",
        name: "positionEdit",
        meta: {
            title: "Position Edit",
            auth: true,
        },
        component: () => import("../../pages/user/hr/career/openPositions/add"),
    },
    {
        path: "/applicationList",
        name: "applicationList",
        meta: {
            title: "Application List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/career/appliedApplications/list"),
    },
    {
        path: "/applicationList/position/:id",
        name: "applicationList",
        meta: {
            title: "Application List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/career/appliedApplications/list"),
    },
    {
        path: "/application/view/:id/",
        name: "applicationView",
        meta: {
            title: "Application View",
            auth: true,
        },
        component: () => import("../../pages/user/hr/career/appliedApplications/add"),
    },
    // sagar created on 30-10-2023
    {
        path: "/courseList",
        name: "courseList",
        meta: {
            title: "course List",
            auth: true,
        },
        component: () => import("../../pages/user/LFC/course/list"),
    },
    {
        path: "/course/add",
        name: "courseAdd",
        meta: {
            title: "Course Add",
            auth: true,
        },
        component: () => import("../../pages/user/LFC/course/add"),
    },
    {
        path: "/course/edit/:id",
        name: "courseEdit",
        meta: {
            title: "Course Edit",
            auth: true,
        },
        component: () => import("../../pages/user/LFC/course/add"),
    },
    // created by sagar 21-12-20
    // {
    //     path: "/eventView",
    //     name: "eventView",
    //     meta: {
    //         title: "Event View",
    //         auth: true,
    //     },
    //     component: () => import("../../pages/user/settings/event/view"),
    // },
    {
        path: "/companyEvents",
        name: "companyEvents",
        meta: {
            title: "Company Events",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/event/cardView"),
    },
    //sagar end
    // edited by dheeraj 11/05/2023
    {
        path: "/attendanceReport",
        name: "attendanceReport",
        meta: {
            title: "Attendance Report",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/attendanceReport"),
    },
    {
        path: "/staffShiftHistory",
        name: "staffShift",
        meta: {
            title: "Staff Shift History",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/staffShift/list"),
    },
    {
        path: "/addStaffShiftChange",
        name: "addStaffShiftChange",
        meta: {
            title: "Add Staff Shift Change",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/staffShift/add"),
    },
    {
        path: "/subordinatesAttendance",
        name: "subordinatesAttendance",
        meta: {
            title: "Subordinates Attendance",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/subordinatesAttendance"),
    },
    {
        path: "/monthlyAttendanceStaffReport",
        name: "monthlyAttendanceStaffReport",
        meta: {
            title: "Monthly Attendance Export",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/monthlyAttendanceExport"),
    },
    {
        path: "/changeRedCrossStatus",
        name: "changeRedCrossStatus",
        meta: {
            title: "Manage Red Cross",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/manageRedCross"),
    },
    {
        path: "/shiftOverride",
        name: "shiftOverride",
        meta: {
            title: "Shift Override",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/shiftOverride/list"),
    },
    {
        path: "/addShiftOverride",
        name: "addShiftOverride",
        meta: {
            title: "Add Shift Override",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/shiftOverride/add"),
    },
    {
        path: "/missedPunchList",
        name: "missedPunchList",
        meta: {
            title: "Forgot To Punch List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/forgotToPunch/list"),
    },
    {
        path: "/addMissedPunch",
        name: "addMissedPunch",
        meta: {
            title: "Add Forgot To Punch",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/forgotToPunch/add"),
    },
    {
        path: "/manageShift",
        name: "manageShift",
        meta: {
            title: "Manage Shift",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/shiftMaster/list"),
    },
    {
        path: "/addUpdateShift",
        name: "addUpdateShift",
        meta: {
            title: "Add/Update Shift",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/shiftMaster/add"),
    },
   // created by sagar  25-09-2023
   {
    path: "/Attendance",
    name: "attendanceIn",
    meta: {
        title: "attendance in",
        auth: true,
    },
    component: () => import("../../pages/user/hr/attendance/attendance/punch"),
},
{
    path: "/AttendanceTimeOut",
    name: "attendanceOut",
    meta: {
        title: "attendance out",
        auth: true,
    },
    component: () => import("../../pages/user/hr/attendance/attendance/punch"),
},
    {
        path: "/helpContent",
        name: "helpContentList",
        meta: {
            title: "Help Content List",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/helpContent/list"),
    },
    {
        path: "/documentCategoryList",
        name: "documentCategoryList",
        meta: {
            title: "Document Category List",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/documentCategory/list"),
    },
    {
        path: "/addDocumentCategory",
        name: "addDocumentCategory",
        meta: {
            title: "Add Document Category Detail",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/documentCategory/addUpdate"),
    },
    {
        path: "/addDocumentCategory/:id",
        name: "editDocumentCategory",
        meta: {
            title: "Edit Document Category Detail",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/documentCategory/addUpdate"),
    },
    {
        path: "/documentTypeList",
        name: "documentTypeList",
        meta: {
            title: "Document Type List",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/documentType/list"),
    },
    {
        path: "/addDocumentType",
        name: "addDocumentType",
        meta: {
            title: "Add Document Type Detail",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/documentType/addUpdate"),
    },
    {
        path: "/addDocumentType/:id",
        name: "editDocumentType",
        meta: {
            title: "Edit Document Type Detail",
            auth: true,
        },
        component: () => import("../../pages/user/settings/master/documentType/addUpdate"),
    },
    // end dheeraj
    {
        path: "/userSearch",
        name: "userSearch",
        meta: {
        title: "User Management",
        auth: true,
        },
        component: () => import("../../pages/user/settings/userManagement/list"),
    },
    {
        path: "/userInformation",
        name: "userInformation",
        meta: {
            title: "User Info",
            auth: true,
        },
        component: () => import("../../pages/user/settings/userManagement/userInforamtion"),
    },
    {
        path: "/userInformation/:id/:isManagerViewing",
        name: "managerView",
        meta: {
            title: "View User Info",
            auth: true,
        },
        component: () => import("../../pages/user/settings/userManagement/userInforamtion"),
    },
    {
        path: "/profileInfo",
        name: "profileInfo",
        meta: {
            title: "Personal Info",
            auth: true,
        },
        component: () => import("../../pages/user/settings/userManagement/userInforamtion/profileInfo"),
    },
    //Start: Mahima on 09-05-2023- Defined Routes
    {
        path: "/blueBook",
        name: "blueBook",
        meta: {
            title: "Blue Book",
            auth: true,
        },
        component: () => import("../../pages/user/hr/blueBook/list"),
    },
    {
        path: "/blueBookDetails/:id",
        name: "blueBookDetails",
        meta: {
            title: "User Detail",
            auth: true,
        },
        component: () => import("../../pages/user/hr/blueBook/detail"),
    },
    {
        path: "/releaseNotesList",
        name: "releaseNotesList",
        meta: {
            title: "Release Notes List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/releaseNote/list"),
    },
    {
        path: "/addUpdateReleaseNotes",
        name: "addUpdateReleaseNotes",
        meta: {
            title: "Add Release Notes",
            auth: true,
        },
        component: () => import("../../pages/user/hr/releaseNote/addUpdate"),
    },
    {
        path: "/addUpdateReleaseNotes/:id",
        name: "editUpdateReleaseNotes",
        meta: {
            title: "Edit Release Notes",
            auth: true,
        },
        component: () => import("../../pages/user/hr/releaseNote/addUpdate"),
    },
    {
        path: "/roleList",
        name: "roleList",
        meta: {
            title: "Role List",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/role/list"),
    },
    {
        path: "/addUpdateRole",
        name: "addUpdateRole",
        meta: {
            title: "Add Role Detail",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/role/addUpdate"),
    },
    {
        path: "/addUpdateRole/:id",
        name: "editRole",
        meta: {
            title: "Edit Role Detail",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/role/addUpdate"),
    },
    {
        path: "/menuList",
        name: "menuList",
        meta: {
            title: "Menu List",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/menu/list"),
    },
    {
        path: "/addUpdateMenu",
        name: "addUpdateMenu",
        meta: {
            title: "Add Menu Detail",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/menu/addUpdate"),
    },
    {
        path: "/addUpdateMenu/:id",
        name: "editMenu",
        meta: {
            title: "Edit Menu Detail",
            auth: true,
        },
        component: () => import("../../pages/user/admin/permissions/menu/addUpdate"),
    },
    {
        path: "/masterDropdown",
        name: "masterDropdown",
        meta: {
            title: "Master DropDown",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterDropdownList/list"),
    },
    {
        path: "/addUpdateDefinitionGroup",
        name: "addUpdateDefinitionGroup",
        meta: {
            title: "Add Definition Detail",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterDropdownList/addUpdate"),
    },
    {
        path: "/addUpdateDefinitionGroup/:id",
        name: "editDefinitionGroup",
        meta: {
            title: "Edit Definition Detail",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterDropdownList/addUpdate"),
    },
    {
        path: "/messageList",
        name: "messageList",
        meta: {
            title: "Message List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/messageList/list"),
    },
    {
        path: "/messageDetails/:id/:type",
        name: "messageDetails",
        meta: {
            title: "Edit Message Detail",
            auth: true,
        },
        component: () => import("../../pages/user/hr/messageList/detail"),
    },
    {
        path: "/toDoList",
        name: "toDoList",
        meta: {
            title: "To Do List",
            auth: true,
        },
        component: () => import("../../pages/user/hr/toDoList/list"),
    },
    {
        path: "/releaseDetail",
        name: "releaseDetail",
        meta: {
            title: "Release Notes",
            auth: true,
        },
        component: () => import("../../pages/user/hr/releaseDetail/detail"),
    },
    {
        path: "/releaseDetail/:id",
        name: "viewReleaseDetail",
        meta: {
            title: "View Release Notes",
            auth: true,
        },
        component: () => import("../../pages/user/hr/releaseDetail/detail"),
    },
    {
        path: "/pageList",
        name: "pageList",
        meta: {
            title: "Master Page",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterPageList/list"),
    },
    {
        path: "/addUpdatePage",
        name: "addUpdatePage",
        meta: {
            title: "Add Page",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterPageList/addUpdate"),
    },
    {
        path: "/addUpdatePage/:id",
        name: "editUpdatePage",
        meta: {
            title: "Edit Page",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterPageList/addUpdate"),
    },
    {
        path: "/awardList",
        name: "awardList",
        meta: {
            title: "Master Award",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterAwardList/list"),
    },
    {
        path: "/addUpdateAward",
        name: "addUpdateAward",
        meta: {
            title: "Add Award",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterAwardList/addUpdate"),
    },
    {
        path: "/addUpdateAward/:id",
        name: "editUpdateAward",
        meta: {
            title: "Edit Award",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/masterAwardList/addUpdate"),
    },
    {
        path: "/suggestionList",
        name: "suggestionList",
        meta: {
            title: "Suggestion",
            auth: true,
        },
        component: () => import("../../pages/user/settings/suggestion/list"),
    },
    {
        path: "/addUpdateSuggestion",
        name: "addUpdateSuggestion",
        meta: {
            title: "Add Suggestion",
            auth: true,
        },
        component: () => import("../../pages/user/settings/suggestion/addUpdate"),
    },
    {
        path: "/addUpdateSuggestion/:id",
        name: "editUpdateSuggestion",
        meta: {
            title: "Edit Suggestion",
            auth: true,
        },
        component: () => import("../../pages/user/settings/suggestion/addUpdate"),
    },
    {
        path: "/probationUpdate",
        name: "probationUpdate",
        meta: {
            title: "Probation Update",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/probationUpdate/add"),
    },
    {
        path: "/helpContent/:name",
        name: "helpContent",
        meta: {
            title: "Help Content",
            auth: true,
        },
        component: () => import("../../pages/user/help"),
    },
    {
        path: "/addMissedPunch/:staffId/:id/:toDoId",
        name: "missedPunchToDO",
        meta: {
            title: "View Forgot To Punch",
            auth: true,
        },
        component: () => import("../../pages/user/hr/attendance/forgotToPunch/update"),
    },
    {
        path: "/leaveApprove/:staffId/:id/:toDoId",
        name: "leaveApproveToDo",
        meta: {
            title: "Leave Approve Details",
            auth: true,
        },
        component: () => import("../../pages/user/hr/leave/leaveApprove/update"),
    },
    {
        path: "/applyForShortLeave/:staffId/:id/:toDoId",
        name: "shortLeaveToDo",
        meta: {
        title: "Short Leave Details",
        auth: true,
        },
        component: () => import("../../pages/user/hr/leave/shortLeave/update"),
    },
    {
        path: "/applyOD/:staffId/:id/:toDoId",
        name: "ODToDo",
        meta: {
        title: "On Duty Request Details",
        auth: true,
        },
        component: () => import("../../pages/user/hr/leave/odRequest/update"),
    },
    {
        path: "/staffDocumentList",
        name: "staffDocumentList",
        meta: {
        title: "Staff Documents List",
        auth: true,
        },
        component: () => import("../../pages/user/hr/documents/staffDocuments/list"),
    },
    {
        path: "/addHRDocuments",
        name: "addHRDocuments",
        meta: {
        title: "Add Documents",
        auth: true,
        },
        component: () => import("../../pages/user/hr/documents/staffDocuments/add"),
    },
    {
        path: "/staffDocumentCheckList",
        name: "staffDocumentCheckList",
        meta: {
        title: "Staff Document CheckList",
        auth: true,
        },
        component: () => import("../../pages/user/hr/documents/documentChecklist/list"),
    },
    {
        path: "/mySalaryFixation",
        name: "mySalaryFixation",
        meta: {
            title: "Salary Fixation",
            auth: true,
        },
        component: () => import("../../pages/user/account/payroll/salaryFixation/detail"),
    },
    {
        path: "/eventList",
        name: "eventList",
        meta: {
        title: "Event List",
        auth: true,
        },
        component: () => import("../../pages/user/hr/leave/event/list"),
    },
    {
        path: "/addUpdateEvent",
        name: "addUpdateEvent",
        meta: {
        title: "Add Event",
        auth: true,
        },
        component: () => import("../../pages/user/hr/leave/event/addUpdate"),
    },
    {
        path: "/addUpdateEvent/:id/:isHeader",
        name: "editUpdateEvent",
        meta: {
        title: "Edit Event",
        auth: true,
        },
        component: () => import("../../pages/user/hr/leave/event/addUpdate"),
    },
    {
        path: "/monthlyAttendanceReport",
        name: "monthlyAttendanceReport",
        meta: {
        title: "Monthly Attendance Report",
        auth: true,
        },
        component: () => import("../../pages/user/hr/reports/monthlyAttendanceReport/list"),
    },
    {
        path: "/monthlyAttendanceReportDetails/:id",
        name: "monthlyAttendanceReportDetails",
        meta: {
            title: "User Monthly Attendance Detail",
            auth: true,
        },
        component: () => import("../../pages/user/hr/reports/monthlyAttendanceReport/detail"),
    },
    {
        path: "/leaveRegisterForHR",
        name: "leaveRegisterForHR",
        meta: {
        title: "Leave Register",
        auth: true,
        },
        component: () => import("../../pages/user/hr/reports/leaveRegisterHR/list"),
    },
    {
        path: "/staffMonthlyAttendanceRegister",
        name: "staffMonthlyAttendanceRegister",
        meta: {
        title: "Attendance Register",
        auth: true,
        },
        component: () => import("../../pages/user/hr/reports/staffMonthlyAttendanceRegister/list"),
    },
    {
        path: "/designationList",
        name: "designationList",
        meta: {
            title: "Designation List",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/designationList/list"),
    },
    {
        path: "/addUpdateDesignation",
        name: "addUpdateDesignation",
        meta: {
            title: "Add Designation",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/designationList/addUpdate"),
    },
    {
        path: "/addUpdateDesignation/:id",
        name: "editUpdateDesignation",
        meta: {
            title: "Edit Designation",
            auth: true,
        },
        component: () => import("../../pages/user/admin/masterData/designationList/addUpdate"),
    },
    // End Mahima
// sagar vueflow test
    {
        path: "/vueFlow",
        name: "vueFlow",
        meta: {
            title: "vueFlow",
            auth: true,
        },
        component: () => import("../../pages/user/vueFlow/add"),
    },
];