export default {
    state: {

        subMenu:[],
        isMenuCollapsed: false,
        breadCrumb:[],
        viweAppraisal:[],
        recaptchaToken: '',

    },
    mutations: {
        subMenu(state, val) {
            state.subMenu = val;
        },
        collapseMenu(state, value) {
            state.isMenuCollapsed = value;
        },
        breadCrumb(state, val) {
            state.breadCrumb = val;
        },
        setViweAppraisal(state,data){
            state.viweAppraisal = data;
        },
        // sagar add this function for store recaptcha token 18-08-2023
        setRecaptcha(state,recaptchaToken){
            state.recaptchaToken = recaptchaToken;
        },
        removeRecaptcha(state){
            state.recaptchaToken = ''
        },
    }
}

