export default {
    //apiUrl: 'http://localhost/intranet_v2/api/api',
    //apiUrl: 'http://inetdevapi.lira2020.com/api/api',
	//apiUrl: 'https://inetstageapi.lira2020.com/api/api',
    apiUrl: 'https://inetapi.netedgecomputing.com/api/api',
    SITEKEY: "6LcC4dMUAAAAALbc6Md4p72rcDAcJ4nHNpNNRrX8", //sagar add site key for captcha varification
    dateFormat: 'DD-MMM-YYYY',
    dateFormatWithTime: 'DD-MMM-YYYY hh:mm A',
    dateFormatWithSlash: 'DD/MM/YYYY',
	dateFormatInNumbersOnly: 'YYYY-MM-DD',
    EDITOR_API_KEY: 'wpura89gl26tra1bl7m7pr58f9th8iztb007mxkpsp7sbgmn',
    //sagar add imageUrl path so that we can use image url to view image
    //imageUrl: 'https://inetdevapi.lira2020.com/api/', //dev
	//imageUrl: 'https://inetstageapi.lira2020.com/api/api', //stage
    imageUrl: 'https://inetapi.netedgecomputing.com/api/', //prod
};
